<template>
  <div class="application-components-index-wrapper">
    <div
      class="application-index-introduction"
    >
      <div class="application-index-introduction-title">{{ $t('applicationIntroductionTitle') }}</div>
      <div class="application-index-introduction-des">
        <p>{{ $t('applicationIntroductionDes[0]') }}</p>
      </div>
    </div>
    <div
      class="application-index-img-list"
    >
      <div
        v-for="(item, index) in computedList"
        :key="index"
        @click="$router.push(item.link)"
      >
        <el-image
          fit="cover"
          :src="item.imgage" 
        />
        <div class="describe"  v-html="$t(item.prop)" />
      </div>
    </div>
  </div>
</template>

<script>
import { configIndex } from '@/utils/index'
export default {
  name: 'applicationComponentsIndex',
  data: () => {
    return {
      computedList: configIndex.sectionFour
    }
  }
}
</script>

<style lang="scss" scoped>
.application-components-index-wrapper {
  .application-index-introduction {
    padding: 20px;
    background: #fff;
    .application-index-introduction-title {
      font-size: 20px;
      font-weight: bold;
      color: #222222;
      line-height: 36px;
      text-align: center;
    }
    .application-index-introduction-des {
      font-size: 13px;
      color: #666666;
      line-height: 24px;
      margin: 12px 0;
    }
  }
  .application-index-img-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px;
    &>div {
      width: 160px;
      height: 193px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      margin-bottom: 15px;
      position: relative;
      border-radius: 10px;
      .el-image {
        width: 100%;
        height: 100%;
        border-radius: 10px 10px 0 0;
        ::v-deep img {
          transform: scale(1.04);
          transition: transform 0.3s;
          &:hover {
            transform: scale(1);
            transition: transform 0.3s;
          }
        }
      }
      .describe {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 193px;
        display: flex;
        flex-direction: column;
        padding: 10px;
        justify-content: flex-end;
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
        background-image: url('~@/assets/image/application/bj.png');
        background-size: 100% 100%;
        pointer-events: none;
        font-family: mySecondFont;
      }
    }
  }
}
</style>